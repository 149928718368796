import { BaseApiClient, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { IdCheckStatus } from "../domain/reservation";

const BASE_URL = "/api/guest-journey-service";

export const convertBlobToBase64 = async (blob: Blob): Promise<string> => {
  // building Promise object by hand since FileReader doesn't support async/Promise syntax
  // "promisify-ing" is not possible as FileReader does not support a callback interface
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("error", reject);
    reader.addEventListener("load", () => {
      resolve(reader.result as string);
    });
    reader.readAsDataURL(blob);
  });
};

class GuestJourneyApiClient extends BaseApiClient {
  async updateReservationIdCheckStatus(arg: { reservationId: string; status: IdCheckStatus }) {
    const response = await this.fetchApi(
      `${BASE_URL}/reservations/${arg.reservationId}/id-check-status?status=${arg.status}`,
      {
        method: "PATCH"
      }
    );
    return new VoidApiResponse(response).value();
  }

  async updateReservationIdCheckStatuses(arg: { reservationIds: string[]; status: IdCheckStatus }) {
    const response = await this.fetchApi(
      `${BASE_URL}/reservations/id-check-status?status=${
        arg.status
      }&reservationIds=${arg.reservationIds.join()}`,
      {
        method: "PATCH"
      }
    );
    return new VoidApiResponse(response).value();
  }

  async getMagicFile(fileName: string): Promise<string> {
    const encodedFileName = encodeURIComponent(fileName);
    let url = BASE_URL.concat(`/files/${encodedFileName}`);
    const response: Response = await this.fetchApi(url);
    const blob = await response.blob();
    return convertBlobToBase64(blob);
  }
}

export const GuestJourneyApi = new GuestJourneyApiClient();
